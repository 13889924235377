<template>
  <the-mask
    v-model="inputValue"
    type="tel"
    class="uc-input"
    :mask="['+## (###)-###-##-##']"
    :readonly="readonly"
    placeholder="+38 (0__) - ___ - __ - __"
    autocomplete="tel"
    @focus.native="onInputFocus"
    @blur.native="onInputBlur"
  />
</template>

<script>
import { TheMask } from 'vue-the-mask';
export default {
  name: 'UcPhoneMask',
  components: { TheMask },
  props: {
    value: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      inputValue: ''
    };
  },
  watch: {
    value: {
      handler(val) {
        this.inputValue = val;
      },
      immediate: true
    },
    inputValue(val) {
      const number = val.replace(/[^0-9]/g, '');
      this.$emit('input', number);
    }
  },
  methods: {
    onInputFocus() {
      if (!this.inputValue || this.inputValue.length < 0) {
        this.inputValue = '+38 (0';
      }
    },
    onInputBlur() {
      if (this.inputValue === '+38 (0') {
        this.inputValue = '';
      }
      this.$emit('blur');
    }
  }
};
</script>
