<template>
  <img
    :key="src"
    :data-lazy="dataLazy"
    :src="srcWithFallback"
    :alt="alt"
    :title="title"
    draggable="false"
    @load="handleLoad"
    @error="handleError"
  />
</template>

<script>
export default {
  name: 'LazyImage',
  props: {
    src: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isFallbackImage: false,
      dataLazy: process.client ? 'loading' : 'loaded'
    };
  },
  computed: {
    srcWithFallback() {
      if (this.isFallbackImage) {
        return `${process.env.BASE_IMG_URL}/images/no_image.jpg`;
      }
      return this.src;
    }
  },
  watch: {
    srcWithFallback() {
      //If src is updated, and we load new image, show spinner
      this.dataLazy = 'loading';
    }
  },
  methods: {
    handleLoad() {
      this.dataLazy = 'loaded'; // css use data-lazy selector for spinner
      this.$emit('loaded'); // slide image need to know when image loaded
    },
    handleError() {
      this.isFallbackImage = true; // src laod failed, show no_image.jpg
    }
  }
};
</script>

<style lang="scss" scoped>
img[data-lazy='loading'] {
  width: 40px;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
  border: 3px solid transparent;
  border-left: 3px solid $main-color;
  border-top: 3px solid $main-color;
  border-radius: 50%;
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
