<template>
  <ValidationProvider
    v-slot="{ errors }"
    ref="provider"
    :name="$t('Телефон')"
    :mode="mode"
    :vid="vid"
    rules="phone-number"
    class="uc-input-field uc-input-has-value"
  >
    <label v-if="withLabel" class="uc-input-label is-required">{{ $t('Телефон') }}<span v-if="withStar">*</span></label>
    <UcPhoneMask v-model="phone" :class="{ 'uc-invalid': errors.length }" :readonly="readonly" />
    <span v-if="errors.length" class="uc-error">
      {{ errors[0] }}
    </span>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

import UcPhoneMask from './UcPhoneMask.vue';

export default {
  components: { ValidationProvider, UcPhoneMask },
  props: {
    withLabel: {
      type: Boolean,
      default: true
    },
    value: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'eager'
    },
    withStar: {
      type: Boolean,
      default: true
    },
    vid: {
      type: String,
      default: 'phone'
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      phone: this.value
    };
  },
  watch: {
    value(nValue, oldValue) {
      if ((nValue && !oldValue) || (nValue && nValue !== oldValue)) {
        this.phone = nValue;
      }
    },
    phone(value) {
      this.$emit('input', value);
    }
  }
};
</script>

<style lang="scss" scoped>
.is-required {
  span {
    color: $red;
  }
}
</style>
