<template>
  <div class="promotions-sliders">
    <div v-if="slidesData.length" class="promotions-slider-row">
      <SwiperSlider
        v-if="slidesData.length"
        class="promotions-slider-swiper"
        :slug="slug"
        :slides="slidesData"
        loop
        pagination
        :options="swiperOption"
      >
        <template #slides="{ slides: items }">
          <div v-for="(slide, index) in items" :key="slide.link + index" class="swiper-slide">
            <slide :slide="slide" :position="index" />
          </div>
        </template>
        <template #fallback>
          <section class="promotions-slider-nojs">
            <uc-link
              v-for="(slide, index) in slides.slice(0, 3)"
              :key="`main-first-swiper-slide-${index}`"
              :to="slide.link || ''"
              class="promotions-slider-item"
            >
              <uc-image :img="slide.img" folder="slider-image" :alt="slide.alt" :title="slide.title" />
            </uc-link>
          </section>
        </template>
      </SwiperSlider>
    </div>
  </div>
</template>

<script>
import { ScreenWidthMixin } from 'u-mixins';
import SwiperSlider from 'u-components/components/SwiperSlider.vue';

import Slide from '~/src/components/main/mainSlider/Slide.vue';

export default {
  components: {
    Slide,
    SwiperSlider
  },
  mixins: [ScreenWidthMixin],
  props: {
    slides: {
      type: Array,
      default: () => []
    },
    slug: {
      type: String,
      default: 'main-slider'
    }
  },
  data() {
    return {
      show: false,
      swiperOption: {
        spaceBetween: 20,
        slidesPerView: 'auto'
      }
    };
  },
  computed: {
    slidesData() {
      const screenType = this.isScreenMobile ? 2 : 1;
      return this.slides.filter((el) => Number(el.type) === screenType);
    }
  },
  mounted() {
    this.show = true;
    this.sendToGtmViewed();
  },
  methods: {
    getGtmPayload(slide, position) {
      if (!slide) {
        return null;
      }
      return {
        promotion_id: slide.promotion_id,
        promotion_name: slide.promotion_name,
        creative_slot: position + 1
      };
    },
    sendToGtmViewed() {
      const payload = this.slidesData.map(this.getGtmPayload);
      this.$gtm.setMultiplePromotionAction(payload, this.$gtm.constructor.PROMOTION_VIEW_KEY);
    }
  }
};
</script>
<style lang="scss">
.promotions-sliders {
  position: relative;
  .promotions-slider-row {
    display: flex;
    align-items: center;
    height: 16vw;

    @media (min-width: $screen-xxl) {
      height: 270px;
    }

    @media (max-width: $screen-lg - 1) {
      height: 14vw;
    }

    @media (max-width: $screen-md - 1) {
      height: 44vw;
    }

    .promotions-slider-swiper {
      flex: 1;

      @media (min-width: $screen-lg) {
        .swiper-button-prev,
        .swiper-button-next {
          content: '';
          width: 16px;
          height: 16px;
          border-right: 7px solid $matisse;
          border-bottom: 7px solid $matisse;
          position: absolute;
          cursor: pointer;

          transform: rotate(135deg);
          left: -45px;
          top: 45%;
        }
        .swiper-button-next {
          transform: rotate(315deg);
          right: -45px !important;
          left: unset;
        }
      }

      .swiper {
        box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);
        position: initial;

        .swiper-slide {
          width: 40%;

          @media (max-width: $screen-md - 1) {
            width: 70%;
          }
        }
        .swiper-pagination {
          display: block;
          position: absolute;
          right: 0;
          left: 0;
          z-index: 2;

          @media (min-width: $screen-xl - 1) {
            bottom: 2px;
          }

          @media (max-width: $screen-lg - 1) {
            display: none;
          }

          .swiper-pagination-bullet {
            width: 12px;
            height: 12px;

            @media (min-width: $screen-xl - 1) {
              width: 16px;
              height: 16px;
            }
          }

          .swiper-pagination-bullet-active {
            background-color: $tundora;
          }
        }
      }

      .promotions-slider-nojs {
        display: flex;
        margin: 32px 0;

        .promotions-slider-item {
          margin-right: 15px;
          box-shadow: none;
        }

        @media (max-width: $screen-md - 1) {
          margin: 0;
          width: 100%;

          .promotions-slider-item {
            margin: 0;
            width: 100%;
          }

          .promotions-slider-item:nth-child(2),
          .promotions-slider-item:nth-child(3) {
            display: none;
          }
        }
      }

      .promotions-slider-item {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);

        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
