export const state = () => ({
  refreshRequestTime: 0
});

export const mutations = {
  // refresh check
  SET_REFRESH_REQUEST_TIME(state, refreshRequestTime) {
    state.refreshRequestTime = refreshRequestTime;
  }
};

export const actions = {
  nuxtServerInit({ dispatch }) {
    return dispatch('catalog/fetchCategoryConfig');
  },
  getPageBySlug(context, params) {
    return this.$api.ContentService.getPageBySlug(params.slug, params.lang);
  }
};
