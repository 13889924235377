import { basketStore } from 'u-store';

const { state: basketState = {}, mutations: basketMutations = {}, actions: basketActions = {} } = basketStore;

export const state = basketState;

export const mutations = {
  ...basketMutations
};

export const actions = {
  ...basketActions
};
