<template>
  <transition v-if="transition" name="grow">
    <div v-if="isSelected">
      <slot />
    </div>
  </transition>
  <div v-else-if="isSelected">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'UcTab',
  props: {
    isSelected: {
      type: Boolean,
      default: false
    },
    transition: {
      type: Boolean,
      default: false
    }
  }
};
</script>
