import Vue from 'vue';
import Router from 'vue-router';
import { EmailConfirmation } from 'u-auth';

import IndexPage from '~/pages/main.vue';
const NotFoundErrorPage = () => import(/* webpackChunkName: "404" */ '~/pages/404.vue').then((res) => res.default);
const InfoPage = () =>
  import(/* webpackChunkName: "info-page" */ '~/pages/info-page/index.vue').then((res) => res.default);
const chiccoTime = () =>
  import(/* webpackChunkName: "chicco-time" */ '~/pages/chicco-time/index.vue').then((res) => res.default);
const NewsDetail = () =>
  import(/* webpackChunkName: "news-detail" */ '~/pages/posts-page/newsDetail.vue').then((res) => res.default);
const News = () => import(/* webpackChunkName: "news" */ '~/pages/posts-page/news.vue').then((res) => res.default);
const Blog = () => import(/* webpackChunkName: "blog" */ '~/pages/posts-page/blog.vue').then((res) => res.default);
const BlogDetail = () =>
  import(/* webpackChunkName: "blog-detail" */ '~/pages/posts-page/blogDetail.vue').then((res) => res.default);
const Promotion = () =>
  import(/* webpackChunkName: "promotion" */ '~/pages/posts-page/promotion.vue').then((res) => res.default);
const PromotionDetail = () =>
  import(/* webpackChunkName: "promotion-detail" */ '~/pages/posts-page/promotionDetail.vue').then(
    (res) => res.default
  );

const MomsSchool = () =>
  import(/* webpackChunkName: "moms-school" */ '~/pages/info-page/about/momsSchool.vue').then((res) => res.default);
const Lecture = () =>
  import(/* webpackChunkName: "lecture" */ '~/pages/info-page/about/lecture.vue').then((res) => res.default);
const SocialProject = () =>
  import(/* webpackChunkName: "social-project" */ '~/pages/info-page/about/socialProject.vue').then(
    (res) => res.default
  );
//
const OrderHistoryListPage = () =>
  import(/* webpackChunkName: "order-history-list" */ '~/pages/personal-account/orderHistory.vue').then(
    (res) => res.default
  );
const BonusProgramPage = () =>
  import(/* webpackChunkName: "bonus-program" */ '~/pages/personal-account/bonusProgram.vue').then(
    (res) => res.default
  );
const PersonalArea = () =>
  import(/* webpackChunkName: "personal-area" */ '~/pages/personal-account/personalArea.vue').then(
    (res) => res.default
  );
const WishList = () =>
  import(/* webpackChunkName: "wishlist" */ '~/pages/personal-account/wishList.vue').then((res) => res.default);
const UserReviewPage = () =>
  import(/* webpackChunkName: "user-review" */ '~/pages/personal-account/myReviews.vue').then((res) => res.default);
const OauthConnectPage = () =>
  import(/* webpackChunkName: "oauth-connect" */ '~/pages/oauth/connect/index.vue').then((res) => res.default);
const OauthPage = () =>
  import(/* webpackChunkName: "oauth-page" */ '~/pages/oauth/index.vue').then((res) => res.default);
const CatalogProductsPage = () =>
  import(/* webpackChunkName: "products" */ '~/pages/catalog/products.vue').then((res) => res.default);
const SearchResultPage = () =>
  import(/* webpackChunkName: "search-results" */ '~/pages/catalog/search-result.vue').then((res) => res.default);
const DetailPage = () =>
  import(/* webpackChunkName: "detail-page" */ '~/pages/product/index.vue').then((res) => res.default);
const OrderPage = () =>
  import(/* webpackChunkName: "order" */ '~/pages/order/orderPage.vue').then((res) => res.default);
const ThanksOrderPage = () =>
  import(/* webpackChunkName: "thanks-order" */ '~/pages/thanks/order.vue').then((res) => res.default);
const ThanksPaymentPage = () =>
  import(/* webpackChunkName: "thanks-payment" */ '~/pages/thanks/payment.vue').then((res) => res.default);
const UiKit = () => import(/* webpackChunkName: "ui-kit" */ '~/pages/ui-kit/uiKit.vue').then((res) => res.default);
const HealthCheck = () =>
  import(/* webpackChunkName: "health-check" */ '~/pages/health-check/index.vue').then((res) => res.default);
const PublicWishList = () =>
  import(/* webpackChunkName: "public-wishlist" */ '~/pages/personal-account/publicWishList.vue').then(
    (res) => res.default
  );
const SubscriptionProfile = () =>
  import(/* webpackChunkName: "subscription" */ '~/pages/personal-account/SubscriptionProfile.vue').then(
    (res) => res.default
  );
const CustomCatalogPage = () =>
  import(/* webpackChunkName: "custom-catalog" */ '~/pages/catalog/customCatalog.vue').then((res) => res.default);

import { getLocalizedRouteName } from '~/utils/i18n-url';
import routerPath from '~/router-path.js';

const i18nConf = require('./i18n.conf.json');

const routeConfig = [
  { name: 'main', path: routerPath.main, component: IndexPage },
  {
    name: 'not-found-error-page',
    path: routerPath.notFoundErrorPage,
    component: NotFoundErrorPage
  },
  { name: 'info-page', path: routerPath.page, component: InfoPage },
  { name: 'chicco-time', path: routerPath.chiccoTime, component: chiccoTime },
  { name: 'moms-school', path: routerPath.momsSchool, component: MomsSchool },
  { name: 'lecture', path: routerPath.lecture, component: Lecture },
  { name: 'social-project', path: routerPath.socialProject, component: SocialProject },
  { name: 'news-page', path: routerPath.news, component: News },
  { name: 'product-detail-page', path: routerPath.product, component: DetailPage },
  { name: 'order-page', path: routerPath.order, component: OrderPage },
  {
    name: 'order-history-page',
    path: routerPath.orderHistory,
    component: OrderHistoryListPage,
    meta: { isAuthorized: true }
  },
  {
    name: 'user-review-page',
    path: routerPath.userReviewPage,
    component: UserReviewPage,
    meta: { isAuthorized: true }
  },
  { name: 'profile-page', path: routerPath.personalArea, component: PersonalArea, meta: { isAuthorized: true } },
  { name: 'email-confirm', path: routerPath.emailConfirm, component: EmailConfirmation },
  {
    name: 'search-result-page',
    path: routerPath.searchResult,
    component: SearchResultPage,
    meta: {
      isCustomCatalog: true,
      isSearch: true
    }
  },
  {
    name: 'thanks-payment-page',
    path: routerPath.thanksPayment,
    component: ThanksPaymentPage
  },
  {
    name: 'thanks-order-page',
    path: routerPath.thanksOrder,
    component: ThanksOrderPage
  },
  {
    name: 'bonus-program-page',
    path: routerPath.bonusProgram,
    component: BonusProgramPage,
    meta: { isAuthorized: true }
  },
  // { name: 'invite-friend-page', path: routerPath.inviteFriend, component: InviteFriendPage },
  {
    name: 'subscription-profile-page',
    path: routerPath.subscriptionProfile,
    component: SubscriptionProfile,
    meta: { isAuthorized: true }
  },
  { name: 'oauth', path: routerPath.oauth, component: OauthPage },
  { name: 'oauth-connect', path: routerPath.oauthConnect, component: OauthConnectPage },
  { name: 'wish-list-page', path: routerPath.wishList, component: WishList },
  {
    name: 'public-wish-list-page',
    path: routerPath.publicWishList,
    component: PublicWishList
  },
  { name: 'promotion-page', path: routerPath.promotion, component: Promotion },
  {
    name: 'promotion-detail-page',
    path: routerPath.promotionDetail,
    component: PromotionDetail
  },
  { name: 'blog-page', path: routerPath.blog, component: Blog },
  { name: 'blog-detail-page', path: routerPath.blogDetail, component: BlogDetail },
  { name: 'news-detail-page', path: routerPath.newsDetail, component: NewsDetail },
  { name: 'personal-area-page', path: routerPath.personalArea, component: PersonalArea, meta: { isAuthorized: true } },
  { name: 'health-check', path: routerPath.healthCheck, component: HealthCheck },
  {
    name: 'outlet-page',
    path: routerPath.outlet,
    component: CustomCatalogPage,
    beforeEnter: withoutSlashEscape,
    meta: {
      isCustomCatalog: true,
      isOutlet: true
    }
  },
  {
    name: 'pakunok-malyuka-page',
    path: routerPath.pakunokMalyuka,
    component: CustomCatalogPage,
    beforeEnter: withoutSlashEscape,
    meta: {
      isCustomCatalog: true,
      isPakunokMalyuka: true
    }
  },
  {
    name: 'custom-catalog-page',
    path: routerPath.customCatalog,
    component: CustomCatalogPage,
    beforeEnter: withoutSlashEscape,
    meta: {
      isCustomCatalog: true
    }
  },
  {
    name: 'product-catalog-page',
    path: routerPath.catalog,
    component: CatalogProductsPage,
    beforeEnter: withoutSlashEscape,
    meta: {
      isCustomCatalog: false
    }
  }
];

if (process.env.NODE_ENV === 'development') {
  routeConfig.unshift({ name: 'ui-kit-page', path: '/ui-kit', component: UiKit });
}

Vue.use(Router);

function withoutSlashEscape(to, from, next) {
  if (to.fullPath.includes('%2F')) {
    const fullPath = to.fullPath.replace('%2F', '/');
    next(fullPath);
  } else {
    next();
  }
}

export function createRouter() {
  const routes = routeConfig.reduce((list, { name, path, component, ...other }) => {
    i18nConf.locales.forEach((locale) => {
      list.push({
        ...other,
        name: getLocalizedRouteName(name, locale.code),
        path: locale.code !== i18nConf.hiddenLocale ? `/${locale.code}${(path !== '/' && path) || ''}` : path,
        component
      });
    });

    return list;
  }, []);

  return new Router({
    mode: 'history',
    base: '/',
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
      return new Promise((resolve) => {
        setTimeout(() => {
          if (savedPosition) {
            resolve(savedPosition);
          } else {
            resolve({ x: 0, y: 0 });
          }
        }, 200);
      });
    }
  });
}
