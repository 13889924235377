<template>
  <ValidationProvider v-slot="{ errors }" :rules="rules" name="name">
    <label
      :for="`uc-checkbox-${name}`"
      class="uc-checkbox-container"
      :class="{ 'uc-checkbox-container_disabled': disabled }"
    >
      <slot name="before-checkbox"></slot>
      <input :id="`uc-checkbox-${name}`" v-model="innerValue" :disabled="disabled" type="checkbox" />
      <span
        class="uc-checkbox"
        :class="{
          invalid: errors.length
        }"
      />
      <slot></slot>
      <slot v-if="errors.length" name="errors" :errorr="errors">
        <p class="uc-error">{{ $t(errors[0]) }}</p>
      </slot>
    </label>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'UcCheckbox',
  components: {
    ValidationProvider
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerValue: this.value
    };
  },
  computed: {
    rules() {
      if (!this.required) {
        return {};
      }
      return { required: { allowFalse: false } };
    }
  },
  watch: {
    value() {
      if (this.value !== this.innerValue) {
        this.innerValue = this.value;
      }
    },
    innerValue() {
      this.$emit('input', this.innerValue);
    }
  }
};
</script>
