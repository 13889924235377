import UcLink from './UcLink.vue';
import UcButton from './UcButton.vue';
import UcCheckbox from './UcCheckbox.vue';
import UcSize from './UcSize.vue';
import UcDiscount from './UcDiscount.vue';
import UcModal from './UcModal.vue';
import UcTab from './UcTab.vue';
import UcTabs from './UcTabs.vue';
import UcImage from './UcImage.vue';
import UcNuxtImage from './UcNuxtImage.vue';
import UcLoader from './UcLoader.vue';
import UcLoaderButton from './UcLoaderButton.vue';
import UcPagination from './UcPagination.vue';
import UcRenderHtml from './UcRenderHtml.vue';
import UcRotateImage from './product/slider/RotateImage.vue';
import UcPhoneMask from './UcPhoneMask.vue';
import UcContentImage from './UcContentImage.vue';
import UcLoadingSpinner from './UcLoadingSpinner.vue';
import UcColorList from './UcColorList.vue';
import UcBreadcrumbs from './UcBreadcrumbs.vue';
import UcInput from './UcInput.vue';
import UcTextarea from './UcTextarea.vue';
import UcPhoneInput from './UcPhoneInput.vue';
import UcDatePicker from './UcDatePicker.vue';
import UcCustomScroll from './UcCustomScroll.vue';
import UcVariants from './UcVariants.vue';
import UcStarRating from './UcStarRating.vue';
import UcForm from './UcForm.vue';
import UcCheckboxPrimitive from './UcCheckboxPrimitive.vue';
import UcLazyImage from './UcLazyImage.vue';

export default {
  UcLink,
  UcButton,
  UcCheckbox,
  UcSize,
  UcDiscount,
  UcModal,
  UcTab,
  UcTabs,
  UcImage,
  UcNuxtImage,
  UcLoader,
  UcLoaderButton,
  UcPagination,
  UcRenderHtml,
  UcRotateImage,
  UcPhoneMask,
  UcContentImage,
  UcLoadingSpinner,
  UcColorList,
  UcBreadcrumbs,
  UcInput,
  UcTextarea,
  UcPhoneInput,
  UcDatePicker,
  UcCustomScroll,
  UcVariants,
  UcStarRating,
  UcForm,
  UcCheckboxPrimitive,
  UcLazyImage
};
