<template>
  <div class="news__item swiper-slide">
    <uc-link
      :title="slide.h1"
      :aria-label="slide.h1"
      :to="localePath({ name: 'news-detail-page', params: { slug: slide.slug } })"
    >
      <uc-image
        class="news-img-desktop"
        :img="slide.mainImage"
        folder="content-image"
        :alt="slide.slug"
        :title="slide.h1"
      />
      <uc-image
        class="news-img-mobile"
        :img="slide.mobileImage"
        folder="content-image"
        :alt="slide.slug"
        :title="slide.h1"
      />
    </uc-link>
  </div>
</template>

<script>
export default {
  props: {
    slide: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.news__item {
  border-radius: 8px;
  cursor: pointer;

  a {
    width: 100%;
  }

  @media (min-width: $screen-xm) {
    min-height: 160px;
  }
  @media (min-width: $screen-xxl) {
    heihgt: 230px;
  }

  width: calc((100% - 60px) / 4);

  @media (max-width: $screen-lg - 1) {
    aspect-ratio: 1/1;
  }

  @media (max-width: $screen-md - 1) {
    width: calc((100% - 20px) / 2);
  }
}
</style>
