<template>
  <div class="detail-variants">
    <div v-if="withTitle" class="detail-head__title">{{ variants.topic?.title }}:</div>
    <div class="detail-variants__wrapper">
      <uc-link
        v-for="variant in variants.products"
        :key="`nomenclature-group-${variant.slug}}`"
        :to="localePath({ name: 'product-detail-page', params: { id: variant.slug } })"
        class="detail-variants__item"
        :class="{ active: activeSlug && variant.slug === activeSlug }"
      >
        {{ variant.title }}
      </uc-link>
    </div>
  </div>
</template>

<script>
import ProductVariants from 'u-models/lib/product/ProductVariants.js';

export default {
  name: 'UcVariants',
  props: {
    variants: {
      type: [ProductVariants, Object],
      required: true
    },
    withTitle: {
      type: Boolean,
      default: true
    },
    activeSlug: {
      type: String,
      default: ''
    }
  }
};
</script>
