<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="label || name"
    :rules="rules"
    class="uc-textarea-field"
    :class="{ 'uc-textarea-has-value': innerValue || placeholder }"
  >
    <slot name="before" />
    <slot v-if="label && !noLabel" name="label" :label="label">
      <label class="uc-textarea-label">{{ $t(label) }}</label>
    </slot>
    <textarea
      v-model="innerValue"
      :name="name"
      class="uc-textarea"
      :placeholder="placeholder"
      :class="{ 'uc-invalid': errors.length, ...additionalClasses }"
      @change="handleChange"
    />
    <slot v-if="errors.length" name="error" :errors="errors">
      <span class="uc-error">{{ errors[0] }}</span>
    </slot>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  components: { ValidationProvider },
  props: {
    value: {
      type: String,
      default: null
    },
    name: {
      type: String,
      required: true
    },
    rules: {
      type: [String, Object],
      default: ''
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    inputClasses: {
      type: [String, Object],
      default: ''
    },
    lazy: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerValue: this.value
    };
  },
  computed: {
    additionalClasses() {
      if (typeof this.inputClasses === 'string') {
        return { [this.inputClasses]: true };
      }
      return this.inputClasses;
    }
  },
  watch: {
    value() {
      if (this.value !== this.innerValue) {
        this.innerValue = this.value;
      }
    },
    innerValue() {
      if (!this.lazy) {
        this.$emit('input', this.innerValue);
      }
    }
  },
  methods: {
    handleChange() {
      if (!this.lazy) {
        return;
      }
      this.$emit('input', this.innerValue);
    }
  }
};
</script>
