<template>
  <section>
    <div v-if="slidesWithSlug.length" class="news">
      <div class="news__head">
        <div class="main-title">{{ $t('Новости') }}</div>
        <uc-link :to="localePath({ name: 'news-page' })" class="news-link">
          {{ $t('Смотреть все') }}
        </uc-link>
      </div>
      <SwiperSlider slug="news" :slides="slidesWithSlug" :options="swiperOption" autoplay pagination>
        <template #slides="{ slides }">
          <Slide v-for="(slide, index) in slides" :key="`news-${index}`" :slide="slide" />
        </template>

        <template #fallback>
          <div class="news-nojs">
            <Slide v-for="(slide, index) in slidesWithSlug.slice(0, 4)" :key="`news-${index}`" :slide="slide" />
          </div>
        </template>
      </SwiperSlider>
    </div>
  </section>
</template>

<script>
import { ScreenWidthMixin } from 'u-mixins';
import SwiperSlider from 'u-components/components/SwiperSlider.vue';

import Slide from '~/src/components/main/NewsSlide.vue';

export default {
  components: { SwiperSlider, Slide },
  mixins: [ScreenWidthMixin],
  props: {
    newsSlides: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      swiperOption: {
        spaceBetween: 20,
        slidesPerView: 4,
        autoplay: {
          delay: 3500,
          disableOnInteraction: false
        },
        breakpoints: {
          767: {
            slidesPerView: 4
          },
          300: {
            slidesPerView: 2
          }
        }
      }
    };
  },
  computed: {
    slidesCount() {
      return this.isScreenMobile ? 2 : 4;
    },
    slidesWithSlug() {
      return this.newsSlides.slides.filter((slide) => slide.slug);
    }
  }
};
</script>

<style lang="scss">
.news {
  @media (max-width: $screen-lg - 1) {
    padding: 0 12px;
  }

  .news__head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .news-link {
      color: $matisse;
      opacity: 0.7;
      margin: 48px 0 12px;

      @media (max-width: $screen-lg - 1) {
        margin: 32px 0 8px;
      }

      @media (max-width: $screen-md - 1) {
        margin: 24px 0 8px;
        font-size: 0.875em;
      }
    }
  }

  .image-wrapper {
    &.news-img-mobile {
      display: none;
    }

    @media (max-width: $screen-lg - 1) {
      &.news-img-desktop {
        display: none;
      }
      &.news-img-mobile {
        display: block;
      }
    }
  }

  .swiper-pagination {
    position: relative;
    bottom: 0;
    top: 0;
    margin: 8px 0;
    display: none;
    @media (max-width: $screen-lg - 1) {
      display: block;
    }
    .swiper-pagination-bullet-active {
      background-color: $tundora;
    }
    .swiper-pagination-bullet {
      background-color: $gray;
    }
  }

  .news-nojs {
    display: flex;

    .news__item {
      margin-right: 20px;
    }

    @media (max-width: $screen-md - 1) {
      margin-right: -4px;
      .news__item:nth-child(3),
      .news__item:nth-child(4) {
        display: none;
      }
    }
  }
}
</style>
